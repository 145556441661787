<template>
  <v-container fluid>
    <crm-table-pageable-new
        :headers="headers"
        :data="data"
        :config="config"
        :loaded-options="options"
        :total-elements="totalElements"
        :total-pages="totalPages"
        @onChangeData="onChangeData"
        @handleActions="handle"
    >
      <template v-slot:last-slot="">
        <v-btn
          v-if="config.add"
          rounded
          large
          color="primary"
          class="mr-3"
          :disabled="!canCopy"
          @click="loadPreviousShowcase()"
        >
          Скопировать
        </v-btn>
      </template>
    </crm-table-pageable-new>
    <v-dialog
      v-model="createDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          Новая витрина
          <v-btn
            text
            fab
            @click="createDialog = false"
            style="position:absolute; right: 0px; top: 0">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field
              placeholder=""
              persistent-placeholder
              label="Наименование"
              outlined
              color="primary"
              class="mb-5"
              :rules="[v => !!v]"
              v-model="item.name"
            />
            <v-btn color="primary"
                   absolute
                   bottom
                   right
                   @click="createShowcase"
                   @keydown.enter.prevent.stop="createShowcase"
            >
              Добавить
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="loadDialog"
      max-width="800"
      persistent>
      <v-card>
        <v-card-title>
          Выберите витрину
        </v-card-title>
        <v-card-text>
          <span>{{previousShowcase}}</span>
          <v-select
            :items="items"
            label="Витрина"
            item-text="name"
            item-value="id"
            v-model="previousShowcase"
          >
            <template v-slot:item="{item, attrs, on}">
              <v-list-item v-on="on" v-bind="attrs" >
                <span>{{item.name}} - &nbsp;
                  <span class='grey--text'>{{ item.id }}</span>
                </span>
              </v-list-item>
            </template>
          </v-select>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn text @click="loadDialog = false">
            Закрыть
          </v-btn>
          <v-btn
            color="primary"
            @click="copy()"
          >
            Загрузить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import { mapState } from 'vuex';

export default {
  name: 'index',
  components: {
    'crm-table-pageable-new': crmTablePageableNew,
  },
  data() {
    return {
      headers: [
        {
          value: 'id', sortable: false, text: '#', type: 'defaultItem',
        },
        {
          value: 'name', sortable: false, text: 'Наименование витрины', type: 'defaultItem',
        },
        {
          value: 'createdByName', sortable: false, text: 'Автор', type: 'defaultItem',
        },
        {
          value: 'createdDate', sortable: true, text: 'Дата создания', type: 'datetime',
        },
        {
          value: 'status', sortable: false, text: 'Статус', type: 'enums',
        },
        {
          value: 'published', sortable: false, text: 'Опубликован', type: 'tableCheckbox',
        },
        {
          text: 'Действие',
          value: 'actions',
          type: 'actions',
          sortable: false,
          align: 'center',
          child: [
            {
              label: 'Редактировать',
              event: 'edit',
              icon: 'mdi-pencil',
              availableConditionName: 'editAvailable',
            },
            {
              label: 'Удалить',
              event: 'delete',
              icon: 'mdi-delete',
              availableConditionName: 'editAvailable',
            },
            {
              label: 'Просмотр',
              event: 'preview',
              icon: 'mdi-eye',
              availableConditionName: 'previewAvailable',
            },
          ],
        },
      ],
      data: [],
      createDialog: false,
      valid: false,
      item: {
        name: null,
      },
      config: {
        disableRowClick: true,
        add: true,
        search: true,
        pageable: true,
        addIcon: true,
      },
      totalElements: 0,
      totalPages: 0,
      url: '/crm/main-page',
      options: {
        page: 1,
        size: 10,
        sort: 'createdDate,desc',
      },
      items: [],
      loadDialog: false,
      previousShowcase: null,
    };
  },
  created() {
    this.onChangeData(this.$route.query);
  },
  computed: {
    ...mapState(['user']),
    isChief() {
      return this.user.roles.includes('ROLE_CHIEF');
    },
    canCopy() {
      return this.user.roles.some(role => ['ROLE_ADMIN', 'ROLE_MARKETER'].includes(role))
    }
  },
  methods: {
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getMainPage();
    },
    getMainPage() {
      const params = this.$route.query;
      const formattedUrl = this.url;

      this.$loading(true);
      this.$newCrmService.get(formattedUrl, {
        params: {
          pageNumber: params.page,
          pageSize: params.size,
          sort: params.sort,
          searchText: params.search
        },
      }).then((response) => {
        this.totalElements = response.recordsTotal;
        this.totalPages = Math.ceil(response.recordsFiltered / (params.size || 1));
        this.data.splice(0)
        this.$nextTick(() => {
          this.data = response.content.map((o) => ({
            ...o,
            checkBoxDisabled: o.status === 'DRAFT',
            editAvailable: o.status !== 'PUBLISHED',
            previewAvailable: o.status === 'PUBLISHED'
          }));
        })
      }).finally(() => {
        this.$loading(false);
      });
    },
    handle(element) {
      switch (element.type) {
        case 'create':
          this.createDialog = true;
          break;
        case 'check':
          this.publishShowcase(element.item.id);
          break;
        case 'info':
          this.$router.push({
            name: 'showcase',
            params: {
              id: element.item.id,
            },
          });
          break;
        case 'delete':
          if (this.isChief) {
            this.$toast.error('У вас нет доступа в этот функционал');
            break;
          }
          this.deleteItem(element);
          break;
        case 'edit':
          if (this.isChief) {
            this.$toast.error('У вас нет доступа в этот функционал');
            break;
          }
          this.$router.push({
            name: 'showcase',
            params: {
              id: element.item.id,
            },
          });
          break;
        case 'preview':
          return this.$router.push({
            name: 'showcase',
            params: {
              id: element.item.id,
            },
            query: {
              isPreview: true,
            }
          })
        default:
          break;
      }
    },
    deleteItem(element) {
      element.event.stopPropagation();
      this.$store.dispatch('tools/showConfirm', {
        title: 'Подтвердите',
        text: 'Вы действительно хотите удалить?',
      }).then(() => {
        this.$newCrmService.delete(`/crm/main-page/${element.item.id}`)
          .then(() => this.onChangeData(this.$route.query))
          .catch((e) => {
            console.log(e);
          });
      }).catch(() => {

      });
    },
    createShowcase() {
      this.$newCrmService.post('/crm/main-page', this.item).then((res) => {
        this.$router.push({
          name: 'showcase',
          params: {
            id: res.content,
          },
        });
      });
    },
    publishShowcase(id) {
      this.$loading(true);
      this.$newCrmService.post(`/crm/main-page/${id}/publish`).then(() => {
        this.onChangeData(this.$route.query);
      }).finally(() => {
        this.$loading(false);
      });
    },
    async loadPreviousShowcase() {
      const response = await this.$newCrmService.get(this.url, {
        params: {
          pageNumber: 1,
          pageSize: 9999,
        },
      });
      this.items = response.content;
      this.loadDialog = true;
    },
    async copy() {
      try {
        await this.$newCrmService.post('/crm/main-page/copy', { mainPageId: this.previousShowcase });
        this.onChangeData(this.$route.query);
      } catch (error) {
        const message = error?.response?.data?.Message;
        if (message) {
          this.$toast.warning(message);
        }
      } finally {
        this.loadDialog = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
